import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import AllData from '@/graphql/AllData.gql'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      id: false,
    },
    calculated: {},
    data: null,
    appContent: null,
    appState: {
      hasAdditionalQuestions: false,
    },
    loading: true,
    csrfToken: '',
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    },

    SET_USER(state, user) {
      state.user = user
    },

    SET_CSRF_TOKEN(state, csrfToken) {
      state.csrfToken = csrfToken
    },

    CHANGE_LOADING_STATUS(state, loading) {
      state.loading = loading
    },
  },
  actions: {
    // Main data object from Craft GraphQL
    loadData({ commit, state }) {
      const dataQuery = {
        query: AllData.loc.source.body,
      }

      // console.log('token:', router.currentRoute.params.token)
      const dataRequest = axios.post('/api', dataQuery, {
        headers: {
          Authorization: `Bearer ${state.user.gqlToken}`,
        },
      })

      const surveyOverviewRequest = axios.get(
        '/internal/surveys/survey-overview.json'
      )

      axios
        .all([dataRequest, surveyOverviewRequest])
        .then(
          axios.spread((...responses) => {
            let data = responses[0].data.data
            let surveyOverview = responses[1].data

            data.assessments = data.assessments.map((assessment) => ({
              ...assessment,
              institutionExtId:
                assessment.institutionAlcId || assessment.institutionSmId,
              studentExtId: assessment.studentAlcId || assessment.studentSmId,
              studentSpExtId:
                assessment.studentSpAlcId || assessment.studentSpSmId,
            }))

            // console.log('surveyOverview:', responses[1].data)
            let ipeds = {}

            // Build out ipeds data
            for (const school of data.schools) {
              if (school.ipeds[0]) {
                ipeds[school.ipeds[0].ipedsNumber] = school.ipeds[0]
              }
            }
            data.ipeds = ipeds
            // move studentQuestionsMedian data up a level in the data object
            data.studentQuestionsMedian = surveyOverview.studentQuestionsMedian
            delete surveyOverview.studentQuestionsMedian
            data.surveys = surveyOverview

            // Replace data.questions with questions lookup
            let questionsLookup = {}
            for (const question of data.questions) {
              questionsLookup[question.id] = question
            }
            data.questions = questionsLookup

            // Replace data.choices with choices lookup
            let choicesLookup = {}
            for (const choice of data.choices) {
              choicesLookup[choice.id] = choice
            }
            data.choices = choicesLookup

            // Add question number (qNum) to questions based on order under principles
            // for institutions...
            for (const principle of data.principles) {
              for (const [
                i,
                question,
              ] of principle.institutionQuestions.entries()) {
                data.questions[question.id].qNum = i + 1
                data.questions[question.id].principle = principle.title
              }
            }

            // ...and for students
            for (const principle of data.principles) {
              for (const [
                i,
                question,
              ] of principle.studentQuestions.entries()) {
                data.questions[question.id].qNum = i + 1
                data.questions[question.id].principle = principle.title
              }
            }

            // Create lookup for student question choices
            data.studentChoicesLookup = {}
            for (const [i, choice] of data.studentQuestionChoices.entries()) {
              data.studentChoicesLookup[choice.id] = {
                score: i + 1,
                title: choice.title,
              }
            }

            // Create lookup for institution question choices
            data.institutionChoicesLookup = {}
            for (const [
              i,
              choice,
            ] of data.institutionQuestionChoices.entries()) {
              data.institutionChoicesLookup[choice.id] = {
                score: i + 1,
                title: choice.title,
              }
            }

            // Ensure relatedPrinciples is defined
            if (data.levels) {
              data.levels.forEach((level) => {
                if (!level.relatedPrinciples) {
                  level.relatedPrinciples = []
                }
              })
            }

            // Set data in store and local storage
            commit('SET_DATA', data)
            localStorage.setItem('data', JSON.stringify(data))

            commit('CHANGE_LOADING_STATUS', false)
          })
        )
        .catch((error) => {
          console.log('err', error)
          return error.response
        })
    },
    stateLoad(context) {
      // get crsf token
      axios
        .get('/api/csrf-token')
        .then((response) => {
          // set csrf token
          context.commit('SET_CSRF_TOKEN', response.data.csrfToken)

          // check if user is logged into craft, if so set user and load data
          axios.get('/api/user').then((response) => {
            // if logged in, allow nav, otherwise force login
            if (response.data.email) {
              // get user data
              this.commit('SET_USER', response.data)

              this.dispatch('loadData')
            } else {
              console.log('logged out')
              localStorage.removeItem('user')
              localStorage.removeItem('data')
              router.push({ name: 'login' }).catch(() => {})
              this.commit('CHANGE_LOADING_STATUS', false)
            }
          })

          // load state from local storage if user previously logged in
          // let user = JSON.parse(localStorage.getItem('user'))
          // let data = JSON.parse(localStorage.getItem('data'))

          // // if localStorage data, load user and data into vuex store
          // if (user && user.id && !context.state.user.id) {
          //   context.state.user = user
          //   context.state.data = data
          // }
        })
        .catch((error) => {
          console.log('err', error)
          return error.response
        })
    },
  },
  getters: {
    loggedIn(state) {
      return !!state.user.id
    },
    data(state) {
      return state.data
    },
    user(state) {
      return state.user
    },
    dataLoading(state) {
      return state.loading
    },
    csrfToken(state) {
      return state.csrfToken
    },
  },
})
