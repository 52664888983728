<template>
  <ul class="button-group">
    <template v-for="level in levels">
      <template v-for="principle in level.principles">
        <li
          v-if="
            (principle.id != 428192 ||
              (principle.id == 428192 && appState.hasAdditionalQuestions)) &&
            ((page_type == 'student' && hasStudentQuestions(principle.id)) ||
              (page_type == 'institution' &&
                hasInstitutionQuestions(principle.id)))
          "
          class="button"
          :class="page_id == principle.id ? 'selected' : ''"
          :key="principle.id"
        >
          <router-link
            :to="`/assessment/${assessment_id}/analyze/${page_type}/${principle.id}`"
          >
            {{ principle.title }}
          </router-link>
        </li>
      </template>
    </template>

    <template v-for="principle in data.principles">
      <li
        v-if="
          principle.id == 426368 || // 426368 is the id of the About Me principle
          (principle.id == 428192 && // 428192 is the id of the Additional Questions principle
            appState.hasAdditionalQuestions &&
            ((page_type == 'student' && hasStudentQuestions(principle.id)) ||
              (page_type == 'institution' &&
                hasInstitutionQuestions(principle.id))))
        "
        class="button"
        :class="page_id == principle.id ? 'selected' : ''"
        :key="principle.id"
      >
        <router-link
          :to="`/assessment/${assessment_id}/analyze/${page_type}/${principle.id}`"
        >
          {{ principle.title }}
        </router-link>
      </li>
    </template>
  </ul>
</template>

<script>
  // import AboveMedian from '@/components/AboveMedian'
  // import Analyze from '@/components/Analyze'
  // import AssessmentDetails from '@/components/AssessmentDetails'
  // import AssessmentList from '@/components/AssessmentList'
  // import AssessmentTable from '@/components/AssessmentTable'
  // import ClassificationDetails from '@/components/ClassificationDetails'
  // import Column from '@/components/Column'
  // import Contacts from '@/components/Contacts'
  // import DocumentLinks from '@/components/DocumentLinks'
  // import Filter from '@/components/Filter'
  // import Subset from '@/components/Subset'
  // import SurveyDetails from '@/components/SurveyDetails'
  // import SurveyStatus from '@/components/SurveyStatus'
  // import TopQuestions from '@/components/TopQuestions'

  export default {
    name: 'PrincipleTabs',
    components: {
      // AboveMedian,
      // Analyze,
      // AssessmentDetails,
      // AssessmentList,
      // AssessmentTable,
      // ClassificationDetails,
      // Column,
      // Contacts,
      // DocumentLinks,
      // Filter,
      // Subset,
      // SurveyDetails,
      // SurveyStatus,
      // TopQuestions,
    },
    props: {
      assessment_id: {
        type: String,
      },
      page_type: {
        type: String,
      },
      page_id: {
        type: String,
      },
    },
    data() {
      return {
        levels: [],
      }
    },
    computed: {},
    methods: {
      getPrincipleDetails(pId) {
        return this.data.principles.find((p) => p.id === pId)
      },
      hasStudentQuestions(pId) {
        //console.log(this.data.principles)
        var sPids = new Array()
        for (const principle of this.data.principles) {
          if (Object.keys(principle.studentQuestions).length > 0) {
            sPids.push(principle.id)
          }
        }
        //console.log(sPids)
        //console.log(pId)
        //console.log(typeof pId)
        //console.log(sPids.includes(pId) ? true : false)
        return sPids.includes(pId) ? true : false
      },
      hasInstitutionQuestions(pId) {
        var iPids = new Array()
        for (const principle of this.data.principles) {
          if (Object.keys(principle.institutionQuestions).length > 0) {
            iPids.push(principle.id)
          }
        }
        return iPids.includes(pId) ? true : false
      },
      buildLevels() {
        let levels = []
        for (let level of this.data.levels) {
          let levelObj = {
            ...level,
            principles: [],
          }
          for (let relatedPrinciple of level.relatedPrinciples) {
            let principle = this.data.principles.find(
              (p) => p.id === relatedPrinciple.id
            )
            if (principle) {
              levelObj.principles.push(principle)
            }
          }
          levels.push(levelObj)
        }
        this.levels = levels
      },
    },
    updated() {},
    mounted() {
      this.buildLevels()
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
</style>
