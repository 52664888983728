<template>
  <div class="comparison-chart">
    <h3>Comparison Chart</h3>

    <div class="chart">
      <div class="ledger"></div>
      <div class="ledger"></div>
      <div class="ledger"></div>
      <div class="ledger"></div>
      <div class="ledger"></div>
      <div class="bottom-ledger"></div>

      <div class="bar-container">
        <template v-for="score in scoreTable">
          <div
            class="bar"
            :style="'height: ' + calcHeight(score.satisfaction)"
            v-bind:key="score.title + 's'"
          >
            <span>{{ score.satisfaction }}</span>
          </div>
          <div
            class="bar"
            :style="'height: ' + calcHeight(score.median)"
            v-bind:key="score.title + 'm'"
          >
            <span>{{ score.median }}</span>
          </div>
          <div
            class="bar"
            :style="'height: ' + calcHeight(score.quality)"
            v-bind:key="score.title + 'i'"
          >
            <span>{{ score.quality }}</span>
          </div>
        </template>
      </div>

      <div class="labels">
        <h4 v-for="score in scoreTable" v-bind:key="score.title">
          {{ score.title }}
        </h4>
      </div>
      <ul class="scale">
        <li>5</li>
        <li>4</li>
        <li>3</li>
        <li>2</li>
        <li>1</li>
        <li>0</li>
      </ul>
    </div>

    <ul class="legend">
      <li>Student Satisfaction</li>
      <li>Median of Student Scores</li>
      <li>Quality (Faculty & Staff)</li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'ComparisonChart',
    props: {
      studentSurvey: {
        type: Object,
      },
      studentSplitSurvey: {
        type: Object,
      },
      institutionSurvey: {
        type: Object,
      },
    },
    data() {
      return {
        headers: [
          {
            text: 'AL360 LEVELS/DOMAINS',
            align: 'start',
            value: 'title',
          },
          { text: 'Satisfaction', value: 'satisfaction' },
          { text: 'Median', value: 'median' },
          { text: 'Quality', value: 'quality' },
        ],
        scoreTable: [],
      }
    },
    methods: {
      calcHeight(score) {
        var floatScore = parseFloat(score)
        //console.log(floatScore)
        if (floatScore > 0) {
          var height = (floatScore / 5) * 100 + '%'
          return height
        } else {
          return '0%'
        }
      },
      scores() {
        //console.log('anything?')
        var sSurvey = this.studentSurvey
        var sSpSurvey = this.studentSplitSurvey
        var iSurvey = this.institutionSurvey

        //console.log('student: ' + sSurvey.principleScores)
        //console.log('institution: ' + iSurvey.principleScores)

        for (let level of this.data.levels) {
          var sLCount = 0
          var iLCount = 0
          var mLCount = 0
          var sLSum = 0
          var iLSum = 0
          var mLSum = 0

          var scoresArray = []

          for (let relatedPrinciple of level.relatedPrinciples) {
            let principle = this.data.principles.find(
              (p) => p.id === relatedPrinciple.id
            )

            if (
              principle.title != 'About Me' &&
              principle.title != 'Additional Questions'
            ) {
              //console.log('principle: ' + principle.id)
              //console.log('score: ' + sSurvey.principleScores[principle.id].q.s)
              var mTotal = 0
              var mCount = 0

              //console.log(iSurvey.title)
              //console.log('sSurvey exists? ' + sSurvey)
              //console.log('sSpSurvey exists? ' + sSpSurvey)
              //console.log('iSurvey exists? ' + iSurvey)

              if (sSpSurvey) {
                if (sSpSurvey.principleScores[principle.id]) {
                  sLCount = sLCount + 1
                  sLSum = sLSum + sSpSurvey.principleScores[principle.id].q.s
                } else if (sSurvey.principleScores[principle.id]) {
                  sLCount = sLCount + 1
                  sLSum = sLSum + sSurvey.principleScores[principle.id].q.s
                }
              } else if (sSurvey) {
                if (sSurvey.principleScores[principle.id]) {
                  sLCount = sLCount + 1
                  sLSum = sLSum + sSurvey.principleScores[principle.id].q.s
                }
              }

              if (iSurvey) {
                if (iSurvey.principleScores[principle.id]) {
                  iLCount = iLCount + 1
                  iLSum = iLSum + iSurvey.principleScores[principle.id].q.s
                }
              }

              for (let q of principle.studentQuestions) {
                if (this.data.studentQuestionsMedian[q.id]) {
                  mTotal += this.data.studentQuestionsMedian[q.id]
                  mCount++
                }
              }

              mLCount = mLCount + mCount
              mLSum = mLSum + mTotal
            }
          }

          var sLRound = this.calculateRound(sLCount, sLSum)
          var mLRound = this.calculateRound(mLCount, mLSum)
          var iLRound = this.calculateRound(iLCount, iLSum)

          let header = {
            title: level.title,
            satisfaction: sLRound,
            median: mLRound,
            quality: iLRound,
          }
          this.scoreTable.push(header)
        }
      },

      calculateRound(count, sum) {
        if (count > 0) {
          var val = sum / count
          var round = val.toFixed(2)
        } else {
          round = ''
        }

        return round
      },
    },
    updated() {},
    mounted() {
      this.scores()
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .comparison-chart {
    margin: 4em 2em 4em 0;
    color: #3a3737;

    h3 {
      margin-bottom: 2em;
    }

    .chart {
      display: grid;
      grid-template-columns: 1fr 34fr;

      .scale {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 7;
        grid-column-end: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: stretch;
        padding: 0;

        li {
          position: relative;
          top: -2em;
          flex-grow: 1;
          flex-basis: 1em;
          line-height: 4em;
          text-align: center;
          color: #3a3737;
        }
      }

      .bar-container {
        grid-row-start: 1;
        grid-column-start: 2;
        grid-row-end: 6;
        grid-column-end: 34;
        display: grid;
        grid-template-columns: repeat(52, 1fr);
        grid-row-gap: 0;
        grid-column-gap: 0;
        position: relative;
        z-index: 2;

        .bar {
          grid-row-start: 1;
          grid-row-end: 2;
          height: 100%;
          align-self: flex-end;
          position: relative;
          z-index: 2;
          background-color: gray;
          border-top-right-radius: 7px;
          border-top-left-radius: 7px;
          filter: drop-shadow(4px 0px 4px rgba(0, 0, 0, 0.1));

          span {
            position: absolute;
            top: -2em;
            left: 0;
            width: 100%;
            height: 1em;
            text-align: center;
            font-weight: 600;
            color: #3a3737;
          }

          &:nth-child(1) {
            grid-column-start: 2;
            grid-column-end: 6;
            background: linear-gradient(180deg, #727f93 0%, #8696ae 100%),
              #ffffff;
          }

          &:nth-child(2) {
            grid-column-start: 7;
            grid-column-end: 11;
            background: linear-gradient(180deg, #894d46 0%, #9d5b53 100%),
              #ffffff;
          }

          &:nth-child(3) {
            grid-column-start: 12;
            grid-column-end: 16;
            background: linear-gradient(180deg, #c5a180 0%, #dcb692 100%),
              #ffffff;
          }

          &:nth-child(4) {
            grid-column-start: 20;
            grid-column-end: 24;
            background: linear-gradient(180deg, #727f93 0%, #8696ae 100%),
              #ffffff;
          }

          &:nth-child(5) {
            grid-column-start: 25;
            grid-column-end: 29;
            background: linear-gradient(180deg, #894d46 0%, #9d5b53 100%),
              #ffffff;
          }

          &:nth-child(6) {
            grid-column-start: 30;
            grid-column-end: 34;
            background: linear-gradient(180deg, #c5a180 0%, #dcb692 100%),
              #ffffff;
          }

          &:nth-child(7) {
            grid-column-start: 38;
            grid-column-end: 42;
            background: linear-gradient(180deg, #727f93 0%, #8696ae 100%),
              #ffffff;
          }

          &:nth-child(8) {
            grid-column-start: 43;
            grid-column-end: 47;
            background: linear-gradient(180deg, #894d46 0%, #9d5b53 100%),
              #ffffff;
          }

          &:nth-child(9) {
            grid-column-start: 48;
            grid-column-end: 52;
            background: linear-gradient(180deg, #c5a180 0%, #dcb692 100%),
              #ffffff;
          }
        }
      }

      .labels {
        grid-row-start: 6;
        grid-column-start: 2;
        grid-row-end: 7;
        grid-column-end: 34;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h4 {
          line-height: 4em;
          flex-basis: 1em;
          flex-grow: 1;
          text-align: center;
          color: #3a3737;

          &:nth-child(2) {
            flex-grow: 1.3;
          }
        }
      }

      .ledger {
        grid-column-start: 2;
        grid-column-end: 34;
        border-top: solid #e1e1e1 1px;
        height: 4em;
        z-index: 1;
        position: relative;

        &:nth-child(1) {
          grid-row-start: 1;
          grid-row-end: 2;
        }

        &:nth-child(2) {
          grid-row-start: 2;
          grid-row-end: 3;
        }

        &:nth-child(3) {
          grid-row-start: 3;
          grid-row-end: 4;
        }

        &:nth-child(4) {
          grid-row-start: 4;
          grid-row-end: 5;
        }

        &:nth-child(5) {
          grid-row-start: 5;
          grid-row-end: 6;
        }
      }

      .bottom-ledger {
        grid-row-start: 6;
        grid-column-start: 2;
        grid-row-end: 7;
        grid-column-end: 34;
        border-top: solid #000 1px;
        line-height: 4em;
        position: relative;
        z-index: 1;
      }
    }

    .legend {
      display: flex;
      flex-direction: row;
      margin: 2em 0 2em 0;
      padding: 0;
      list-style-type: none;
      align-items: center;
      justify-content: center;
      font-size: 0.75em;

      li {
        display: flex;
        align-items: center;
        margin-right: 2em;
        justify-content: center;
        color: #3a3737;

        &:before {
          content: '';
          display: inline-block;
          width: 1.5em;
          height: 1.5em;
          margin-right: 0.5em;
          background-color: #8595ac;
        }

        &:nth-child(2) {
          &:before {
            background-color: #9d5b53;
          }
        }

        &:nth-child(3) {
          &:before {
            background-color: #dcb692;
          }
        }
      }
    }
  }
</style>
