<template>
  <div class="assessment-table">
    <v-data-table
      no-data-text="No Scores Available"
      :headers="headers"
      :items="scoreTable"
      dense
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item="props">
        <tr :class="props.item.className">
          <td class="text-start">{{ props.item.title }}</td>
          <td class="text-start">{{ props.item.satisfaction }}</td>
          <td class="text-start">{{ props.item.median }}</td>
          <td class="text-start">{{ props.item.quality }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    name: 'AssessmentTable',
    props: {
      studentSurvey: {
        type: Object,
      },
      studentSplitSurvey: {
        type: Object,
      },
      institutionSurvey: {
        type: Object,
      },
    },
    data() {
      return {
        headers: [
          {
            text: 'AL360 LEVELS/DOMAINS',
            align: 'start',
            value: 'title',
          },
          { text: 'Satisfaction', value: 'satisfaction' },
          { text: 'Median', value: 'median' },
          { text: 'Quality', value: 'quality' },
        ],
        scoreTable: [],
      }
    },
    methods: {
      scores() {
        //console.log('anything?')
        var sSurvey = this.studentSurvey
        var sSpSurvey = this.studentSplitSurvey
        var iSurvey = this.institutionSurvey

        //console.log('student: ' + sSurvey.principleScores)
        //console.log('institution: ' + iSurvey.principleScores)

        for (let level of this.data.levels) {
          var sLCount = 0
          var iLCount = 0
          var mLCount = 0
          var sLSum = 0
          var iLSum = 0
          var mLSum = 0

          var scoresArray = []

          for (let relatedPrinciple of level.relatedPrinciples) {
            let principle = this.data.principles.find(
              (p) => p.id === relatedPrinciple.id
            )

            if (
              principle.title != 'About Me' &&
              principle.title != 'Additional Questions'
            ) {
              //console.log('principle: ' + principle.id)
              //console.log('score: ' + sSurvey.principleScores[principle.id].q.s)
              var sRound
              var iRound
              var mTotal = 0
              var mCount = 0

              //console.log(iSurvey.title)
              //console.log('sSurvey exists? ' + sSurvey)
              //console.log('sSpSurvey exists? ' + sSpSurvey)
              //console.log('iSurvey exists? ' + iSurvey)

              if (sSpSurvey) {
                if (sSpSurvey.principleScores[principle.id]) {
                  sRound =
                    sSpSurvey.principleScores[principle.id].q.s.toFixed(2)
                  sLCount = sLCount + 1
                  sLSum = sLSum + sSpSurvey.principleScores[principle.id].q.s
                } else if (sSurvey.principleScores[principle.id]) {
                  sRound = sSurvey.principleScores[principle.id].q.s.toFixed(2)
                  sLCount = sLCount + 1
                  sLSum = sLSum + sSurvey.principleScores[principle.id].q.s
                } else {
                  sRound = ''
                }
              } else if (sSurvey) {
                sRound = sSurvey.principleScores[principle.id]
                  ? sSurvey.principleScores[principle.id].q.s.toFixed(2)
                  : ''

                if (sSurvey.principleScores[principle.id]) {
                  sLCount = sLCount + 1
                  sLSum = sLSum + sSurvey.principleScores[principle.id].q.s
                }
              } else {
                sRound = ''
              }

              if (iSurvey) {
                iRound = iSurvey.principleScores[principle.id]
                  ? iSurvey.principleScores[principle.id].q.s.toFixed(2)
                  : ''

                if (iSurvey.principleScores[principle.id]) {
                  iLCount = iLCount + 1
                  iLSum = iLSum + iSurvey.principleScores[principle.id].q.s
                }
              }

              for (let q of principle.studentQuestions) {
                if (this.data.studentQuestionsMedian[q.id]) {
                  mTotal += this.data.studentQuestionsMedian[q.id]
                  mCount++
                }
              }

              mLCount = mLCount + mCount
              mLSum = mLSum + mTotal

              // Only calculate median values if there are values to be counted
              var mRound = this.calculateRound(mCount, mTotal)

              let score = {
                title: principle.title,
                satisfaction: sRound,
                median: mRound,
                quality: iRound,
                className: 'data',
              }
              //console.log(score)

              scoresArray.push(score)
            }
          }

          var sLRound = this.calculateRound(sLCount, sLSum)
          var mLRound = this.calculateRound(mLCount, mLSum)
          var iLRound = this.calculateRound(iLCount, iLSum)

          let header = {
            title: level.title,
            satisfaction: sLRound,
            median: mLRound,
            quality: iLRound,
            className: 'header',
          }
          this.scoreTable.push(header)

          scoresArray.forEach((score) => {
            this.scoreTable.push(score)
          })
        }
      },

      calculateRound(count, sum) {
        if (count > 0) {
          var val = sum / count
          var round = val.toFixed(2)
        } else {
          round = ''
        }

        return round
      },
    },
    updated() {},
    mounted() {
      this.scores()
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .assessment-table {
    font-size: 0.8571428em;

    .v-icon {
      &.strength {
        color: @brand-green;
      }

      &.challenge {
        color: @brand-red;
      }

      &.disconnect {
        color: @brand-yellow;
      }
    }

    table {
      thead {
        th {
          border-width: 0 !important;
          border-left: solid #00a94e 1px !important;

          &:first-child {
            border-left-width: 0 !important;
          }
        }
      }
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: @brand-green-pale;
          }

          &:nth-child(even) {
            background: none;
          }

          &.header {
            background-color: #00a94e;
            color: white;

            td {
              color: white;
              font-weight: 700;
            }
          }

          td {
            border-width: 0 !important;
            border-left: solid #00a94e 1px !important;

            &:first-child {
              border-left-width: 0 !important;
            }
          }
        }
      }
    }

    .legend {
      display: flex;
      flex-direction: row;
      margin: 2em 0 2em 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        margin-right: 2em;

        .v-icon {
          margin-right: 0.1em;
        }
      }
    }
  }
</style>
